.App {
  width: 100%;
  height:100vh;
  min-height:900px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position:relative;
}

.landing-app {
  background-image: url('./assets/landing_background.jpg');
}

.info-text {
  font-size: 18pt;
  text-shadow: 0 0 8px #FF0000, 0 0 10px #FFFFFF;
}

.mod-button:hover {
  background: #ffffff12;
    text-shadow: 0 0.01em 0.2em #b1a3ff, 0 0.01em 1em #b1a3ff;
}

.mod-button {
  position: relative;
    overflow: hidden;
    height: 100px;
    display: flex;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    min-width: 200px;
    padding: 1em;
    background: url(http://api.thumbr.io/whitenoise-354x370.png?background=ffffff00&noise=ffffff&density=40&opacity=4), rgba(255, 255, 255, 0.01);
    transition: 0.5s ease-in-out;
    transition-property: background, text-shadow;
    -webkit-backdrop-filter: blur(0.083em);
    backdrop-filter: blur(0.083em);
    border-radius: 1em;
    font-family: inherit;
    font-size: 18pt;
    text-align: center;
    letter-spacing: 0.05em;
    color: white;
    text-shadow: 0 0.01em 0.2em rgba(227, 222, 255, 0.333), 0 0.01em 0.5em rgba(227, 222, 255, 0.25);
    box-shadow: inset 0 0.04em 0.18em rgba(154, 146, 210, 0.3), inset 0 -0.82em 0.68em -0.64em rgba(96, 68, 144, 0.3), inset 0 0.07em 0.11em -0.04em white, inset 0 0.39em 0.56em -0.36em rgba(255, 255, 255, 0.5);
    /* box-shadow: inset 0 0.01em 0.4em rgba(227, 222, 255, 0.2), inset 0 0.04em 0.18em rgba(154, 146, 210, 0.3), inset 0 0.98em 1em rgba(202, 172, 255, 0.3), inset 0 -0.82em 0.68em -0.64em rgba(96, 68, 144, 0.3), inset 0 0.07em 0.11em -0.04em white, inset 0 0.39em 0.56em -0.36em rgba(255, 255, 255, 0.5); */
    align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.home-image1 img {
  width:200px;
  height:auto;
}

.home-image1 {
  position:absolute;
  left:10%;
  top:10%;
  padding:15px;
  border-radius:10px;
  background-color:#f09af6;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  opacity:0.3;
  animation-name:image1;
  animation-timing-function: linear;
  animation-duration:10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes image1 {
  0%   {left:10%; top:10%;}
  25%  {left:15%; top:10%;}
  50%  {left:15%; top:15%;}
  75%  {left:10%; top:15%;}
  100% {left:10%; top:10%;}
}

.home-image2 img {
  width:250px;
  height:auto;
}

.home-image2 {
  position:absolute;
  left:20%;
  bottom:30%;
  padding:15px;
  border-radius:10px;
  background-color:#6ddbf9;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  opacity:0.3;
}

.home-image3 img {
  width:250px;
  height:auto;
}

.home-image3 {
  position:absolute;
  right:10%;
  bottom:10%;
  padding:15px;
  border-radius:10px;
  background-color:#f1e98c;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  opacity:0.3;
}


.online-app {
  background-image: url('./assets/online_background.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}

.main-body {
  width:100%;
  display:flex;
  column-gap:20px;
}

.pay-div {
  width:100%;
}

.left-name {
  width:40%;
  text-align: center;
  color:#835a94;
  font-weight:800;
  text-shadow: -1px 1px 1px #0000aa;
}

.text-shadow-left {
  text-shadow: -1px 1px 1px #0000aa;
}

.right-name {
  width:40%;
  text-align: center;
  color:#b3a855;
  font-weight:800;
  text-shadow: 1px 1px 1px #aa0000;
}

.text-shadow-right {
  text-shadow: 1px 1px 1px #aa0000;
}

.middle-money {
  width:20%;
  text-align: center;
  font-weight: 500;
}

.title-font {
  font-size:60px;
  padding:20px 0;
}

.select-div {
  display:none;
}

.round-title-input {
  width:300px;
  background-color: rgba(255, 255, 255, 0.8);
  outline:none;
  color:black;
  border-radius:3px;
  font-size:15px;
  padding:0px 5px;
}

.title-input-button {
  background-color: #aa0000;
  color:white;
  font-size:15px;
  padding:3px 20px;
  border-radius: 5px;
  cursor:pointer;
}

.input-div {
  display:flex;
  column-gap:20px;
  justify-content: center;
}

@media screen and (max-width:500px) {
  .title-font {
    font-size:40px;
  }
}

@media screen and (max-width:700px) {
  .list-div {
    display:none;
  }
  .emoji-img {
    width:60px;
    height: auto;
  }
  .money-bag-img {
    width:60px;
  }
  .round-title-div {
    font-size:25px;
    padding-top:30px;
  }
  .select-div {
    display:block;
  }
  .input-div {
    flex-direction: column;
    padding:0px 20px;
    row-gap: 10px;
  }
  .round-title-input {
    width:100%;
  }
}

.preference-modal-back {
  position:absolute;
  left:0px;
  top:0;
  width:100%;
  height:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.5);
  z-index:100;  
}

.preference-modal-container {
  width:100%;
  max-width:500px;
  min-height:200px;
  padding:10px;
  border-radius: 10px;
  background-color: rgb(255,255,255);
  box-shadow: 0px 0px 4px 4px rgba(95, 255, 231, 0.4);
  
}

.preference-row {
  width:100%;
  display:flex;
  align-items: center;
  padding:10px;
}

.selector-div {
  width:40%;
  font-size:20px;
  font-weight:800;
  text-align: left;
  padding-left:5%;
  color:#51a75d;
}

.selected-div {
  width:60%;
}

.selected-div select {
  width:80%;
  outline:none;
  font-size:18px;
  font-weight: 600;
  cursor:pointer;
  border: 2px solid rgb(109, 195, 252);
  border-radius:5px;
  color:#4f4f50;
  padding:3px;
  background-color: rgb(200,200,200);
}

.selected-div select:hover {
  background-color: rgb(230,230,230);
}

.selected-div select option {
  font-size:14px;
}

.preference-title {
  width:100%;
  text-align: center;
  font-size:40px;
  padding:20px 0;
  font-weight:800;
  color:#51a75d;
  text-shadow: 1px 1px 1px #0000aa;
}

.modal-image-div {
  position:absolute;
  right:-80px;
  top:-50px;
  z-index:101;
  width:150px;
  padding:10px;
  border-radius: 10px;
  background-color: rgba(109, 195, 252, 0.9);
}
.modal-image-div img {
  width:100%;
  height:auto;
}

.modal-button-div {
  width:100%;
  display:flex;
  justify-content: right;
  align-items: center;
  column-gap: 20px;
  padding:20px;
}

.modal-button {
  color:white;
  font-size:18px;
  font-weight:600;
  padding:5px 20px;
  border-radius:5px;
  cursor:pointer;
}
.modal-apply-button {
  background-color: rgba(0,132,195);
  z-index:103;
}

.modal-apply-button:hover {
  background-color: rgba(0,132,195, 0.7);
  font-weight:900;
}

.modal-cancel-button {
  background-color: rgba(0,132,195);
  z-index:103;
}

.modal-cancel-button:hover {
  background-color: rgba(0,132,195, 0.7);
  font-weight:900;
}

.modal-chip-image {
  position:absolute;
  left:10px;
  bottom:10px;
  width:100px;
  height:auto;
}

.modal-chip-image img {
  width:100%;
}

@media screen and (max-width:700px) {
  .modal-image-div {
    display:none;
  }
}

.font-family1 {
  font-family: 'Caprasimo', cursive;
}

.font-family2 {
  font-family: 'Lilita One', cursive;
}

.font-family3 {
  font-family:"libre_baskervillebold";
  font-weight:800;
}

.font-family4 {
  font-family:"Anton", cursive;
}

.home-container {
  display:flex;
  width:100%;
  height:100vh;
  min-height:900px;
  position:relative;
}

.calculate-container {
  width:50%;
  height:100%;
  position:relative;
  box-shadow: 1px 1px 5px 5px #00223e;
  z-index:100;
}

.layer1_background {
  background: #e74c3c;
  background: -moz-linear-gradient( 45deg, #e74c3c 0%, #00223e 39%, #00223e 52%, #00223e 73%, #00223e 100% );
  background: -webkit-linear-gradient( 45deg, #e74c3c 0%, #00223e 39%, #00223e 52%, #00223e 73%, #00223e 100% );
  background: linear-gradient( 45deg, #e74c3c 0%, #00223e 39%, #00223e 52%, #00223e 73%, #00223e 100% );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e74c3c', endColorstr='#00223e',GradientType=1 );
  background-size: 150% 150%;
  -webkit-animation: Gradient 8s ease infinite;
  -moz-animation: Gradient 8s ease infinite;
  animation: Gradient 8s ease infinite;
}
@keyframes Gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.gradient-style {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index:-1;
}

.calculate-container .title-div {
  width:100%;
  text-align: center;
  margin-top:10%;
  font-size:40px;
  font-weight:1000;
  color:white;
  padding:20px 0;
  opacity:0.8;
}

.calculate-container .body {
  width:100%;
  display:flex;
  justify-content: center;
}


.w-80p {
  width:80%;
}

.body .table-row {
  display:flex;
  justify-content: space-between;
}

.body .table-content {
  min-height:380px;
}

.w-40p {
  width:40%;
}

.w-20p {
  width:20%;
}

.table-row .title {
  font-size:20px;
  font-weight:800;
  text-align: center;
  color:#cccccc;
  word-wrap: break-word;
}

.table-row .input {
  font-size:18px;
  text-align: center;
  padding:5px 1%;
}

.table-row .input input {
  width:100%;
  border-radius:5px;
  background-color: #112a3f;
  outline:none;
  padding:5px;
}

.body .button {
  width:100%;
  display:flex;
  justify-content: right;
  column-gap: 5px;
  align-items: center;
  padding:10px;
}

.body .button .calculate {
  background-color: #00223e;
  padding:5px 20px;
  border-radius:5px;
  cursor:pointer;
}

.body .button .calculate:hover {
  color: rgb(207, 207, 207);
}


.body .button .prefer {
  background-color: #00223e;
  padding:9px 20px;
  border-radius:5px;
  cursor:pointer;
}

.body .button .prefer:hover {
  color: rgb(207, 207, 207);
}

.preference-modal-back {
  position:absolute;
  left:0px;
  top:0;
  width:100%;
  height:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.9);
  z-index:1000;  
}

.preference-modal-container2 {
  width:100%;
  max-width:500px;
  min-height:200px;
  padding:10px;
  border-radius: 10px;
  background-color: #295172;
  box-shadow: 0px 0px 4px 4px rgba(200, 200, 200, 0.4);
  
}

.preference-modal-container2 .preference-row {
  width:100%;
  display:flex;
  align-items: center;
  padding:10px;
}

.preference-modal-container2 .selector-div {
  width:40%;
  font-size:20px;
  font-weight:800;
  text-align: left;
  padding-left:10%;
  color:#cccccc;
}

.preference-modal-container2 .selected-div {
  width:60%;
}

.preference-modal-container2 .selected-div select {
  width:80%;
  outline:none;
  font-size:18px;
  font-weight: 600;
  cursor:pointer;
  border: 2px solid rgb(109, 195, 252);
  border-radius:5px;
  color:#4f4f50;
  padding:3px;
  background-color: rgb(200,200,200);
}

.preference-modal-container2 .selected-div select:hover {
  background-color: rgb(230,230,230);
}

.preference-modal-container2 .selected-div select option {
  font-size:14px;
}

.preference-modal-container2 .preference-title {
  width:100%;
  text-align: center;
  font-size:40px;
  padding:20px 0;
  font-weight:800;
  color:#cccccc;
  text-shadow: 1px 1px 1px #0000aa;
}

.preference-modal-container2 .modal-image-div {
  position:absolute;
  right:-80px;
  top:-50px;
  z-index:101;
  width:150px;
  padding:10px;
  border-radius: 10px;
  background-color: rgba(109, 195, 252, 0.9);
}
.preference-modal-container2 .modal-image-div img {
  width:100%;
  height:auto;
}

.preference-modal-container2 .modal-button-div {
  width:100%;
  display:flex;
  justify-content: right;
  align-items: center;
  column-gap: 20px;
  padding:20px;
}

.preference-modal-container2 .modal-button {
  color:white;
  font-size:18px;
  font-weight:600;
  padding:5px 20px;
  border-radius:5px;
  cursor:pointer;
}
.preference-modal-container2 .modal-apply-button {
  background-color: rgba(0,132,195);
  z-index:103;
}

.preference-modal-container2 .modal-apply-button:hover {
  background-color: rgba(0,132,195, 0.7);
  font-weight:900;
}

.preference-modal-container2 .modal-cancel-button {
  background-color: rgba(0,132,195);
  z-index:103;
}

.preference-modal-container2 .modal-cancel-button:hover {
  background-color: rgba(0,132,195, 0.7);
  font-weight:900;
}

.preference-modal-container2 .modal-chip-image {
  position:absolute;
  left:10px;
  bottom:10px;
  width:100px;
  height:auto;
}

.preference-modal-container2 .modal-chip-image img {
  width:100%;
}

@media screen and (max-width:700px) {
  .modal-image-div {
    display:none;
  }
}

.result-container {
  width:50%;
  height:100%;
  
  background-color: #00223e;
}

.background-effect {
  width:100%;
  height:100%;
  background-color:rgba(7, 9, 10, 0.1);
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.left-name2 {
  width:40%;
  text-align: center;
  font-size:27px;
  color:#dddddd;
  font-weight:700;
}

.right-name2 {
  width:40%;
  text-align: center;
  font-size:27px;
  color:#dddddd;
  font-weight:700;
}

.middle-money2 {
  width:20%;
  text-align: center;
  font-size:27px;
  font-weight: 700;
}

.left-name2-title {
  width:40%;
  text-align: center;
  font-size:27px;
  color:#dddddd;
  font-weight:800;
  text-shadow: -1px 1px 1px #0000aa;
}

.right-name2-title {
  width:40%;
  text-align: center;
  font-size:27px;
  color:#dddddd;
  font-weight:800;
  text-shadow: 1px 1px 1px #aa0000;
}

.middle-money2-title {
  width:20%;
  text-align: center;
  font-size:30px;
  font-weight: 1000;
  text-shadow: -1px 1px 1px #45b145;
}

.result-body {
  width:80%;
  margin-top:20px;
}

@keyframes opacity-loop {
  0%   {opacity:0.02}
  50%  {opacity:0.15}
  100% {opacity:0.02}
}

.image-opacity {
  animation-name: opacity-loop;
  animation-duration:10s;
  animation-iteration-count:infinite;
}

.text-sha1 {
  text-shadow: 2px 2px #680101;
}

@media screen and (max-width:500px) {
  .selector-div {
    font-size: 18px;
    padding-left:0;
  }
}

@media screen and (max-width:860px) {
  .home-container {
    flex-direction:column;
  }
  .calculate-container {
    width:100%;
    padding-bottom:20px;
  }
  .result-container {
    width:100%;
  }
  .result-body {
    margin-bottom:20px;
  }
}

@media screen and (max-width:600px) {
  .button-div {
    flex-direction: column;
    row-gap: 20px;
  }
  .home-image1 {
    display:none;
  }
  .home-image2 {
    display:none;
  }
  .home-image3 {
    display:none;
  }
  .table-row .title {
    font-size:16px;
  }
  .calculate-container .title-div {
    font-size:30px;
    margin-top:10px;
  }
  .left-name2-title, .right-name2-title, .middle-money2-title, .left-name2, .middle-money2, .right-name2 {
    font-size:20px;
  }
  
}